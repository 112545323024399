import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'bv-i18n';

import MarketFilterSlider from 'InPlayOverview/components/market_filter/market_filter_slider';
import CompetitionContext from 'InPlayOverview/services/competition_context';
import MarketfilterContext from 'InPlayOverview/services/marketfilter_context';
import Pager from 'InPlayOverview/components/coupons/pager';
import Competition from './competition';

const CompetitionsComponent = ({
  sport, widget, showSportHeader, showMarketFilter, treeType,
}) => {
  const { selectedMarketType, selectedPeriod } = sport || {};
  // change _ to - and remove tailing numbers (think of template_b1)
  const couponClass = selectedMarketType?.couponName.replace('_', '-').replace(/\d$/, '');

  const marketFilterContextValue = useMemo(() => ({
    marketType: selectedMarketType,
    period: selectedPeriod,
    sport,
  }), [sport]);

  const competitions = (sport && sport.comp) || [];

  const contexts = useMemo(() => competitions.reduce((acc, competition) => {
    acc[competition.id] = {
      marketType: selectedMarketType,
      period: selectedPeriod,
      sport,
      competition,
      widget,
    };
    return acc;
  }, {}), [competitions, selectedMarketType, selectedPeriod, sport, widget]);

  if (competitions.length === 0) {
    return (
      <div className={!widget && 'bvs-card inplay'}>
        <div className={`inplay-coupon inplay-${couponClass}-coupon`}>
          { treeType === 'in_play'
            ? t('javascript.in_play.no_live_events')
            : t('javascript.in_play.no_upcoming_events') }
        </div>
        {/* Pager should go here */}
      </div>
    );
  }

  return (
    <>
      { !showSportHeader && showMarketFilter && sport.marketTypes.length > 1 && (
      <MarketfilterContext.Provider value={marketFilterContextValue}>
        <MarketFilterSlider marketTypes={sport.marketTypes} />
      </MarketfilterContext.Provider>
      )}
      <div className="inplay-coupon-sport-group">
        { showSportHeader && (
        <div className="inplay-coupon-sport-name">
          <h2 className="bvs-header">{sport.desc}</h2>
        </div>
        )}
        { showSportHeader && showMarketFilter && sport.marketTypes.length > 1 && (
        <MarketfilterContext.Provider value={marketFilterContextValue}>
          <MarketFilterSlider marketTypes={sport.marketTypes} />
        </MarketfilterContext.Provider>
        )}
        {
        competitions.map((competition) => (
          <CompetitionContext.Provider value={contexts[competition.id]}>
            <Competition
              key={competition.id}
              couponName={selectedMarketType.couponName}
              headers={selectedMarketType.headers}
              competition={competition}
              widget={widget}
            />
          </CompetitionContext.Provider>
        ))
      }
        {
        !widget
        && sport.numberOfEvents > sport.eventsShown
        && (
        <Pager
          sportId={sport.id}
          eventIncrement={sport.eic}
          numberOfEvents={sport.numberOfEvents}
          eventsShown={sport.eventsShown}
        />
        )
      }
      </div>
    </>
  );
};

CompetitionsComponent.propTypes = {
  sport: PropTypes.instanceOf(Object).isRequired,
  widget: PropTypes.bool.isRequired,
  showSportHeader: PropTypes.bool.isRequired,
  showMarketFilter: PropTypes.bool,
  treeType: PropTypes.string.isRequired,
};

CompetitionsComponent.defaultProps = {
  showMarketFilter: false,
};

const mapStateToProps = (state) => ({
  treeType: state.inplay.treeType,
  widget: state.inplay.widget,
});

export default connect(mapStateToProps)(CompetitionsComponent);
