import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeGetVisibleEvents, makeGetFirstScoreboard } from 'InPlayOverview/selectors';
import { isMultiMarketTemplate } from 'InPlayOverview/helpers/is_multi_market_template';
import couponFactory from 'InPlayOverview/services/coupon_factory';
import CouponHeader from 'InPlayOverview/components/coupons/coupon_header';
import CompetitionHeader from 'InPlayOverview/components/competitions/competition_header';

import { withCompetitionContext } from 'InPlayOverview/helpers/competition';

export const CompetitionComponent = (props) => {
  const {
    couponName, visibleEvents, competition, widget,
  } = props;
  const Coupon = couponFactory(couponName);
  const couponClass = couponName.replace('_', '-').replace(/\d$/, '');

  const [folded, setFolded] = useState(false);
  const toggle = useCallback(() => {
    setFolded(!folded);
  }, [folded, setFolded]);

  if (!visibleEvents.length) {
    return null;
  }

  return (
    <div className={classnames({ 'bvs-card inplay': !widget, 'inplay-coupon-comp-group': widget, collapsed: folded })}>
      <CompetitionHeader description={competition.desc} toggle={toggle} folded={folded} />
      <div className={classnames('inplay-coupon', `inplay-${couponClass}-coupon`, { 'inplay-multi-market': isMultiMarketTemplate(couponName) })}>
        <CouponHeader {...props} />
        <Coupon coupon={couponName} events={competition.events} />
      </div>
    </div>
  );
};

CompetitionComponent.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  headers: PropTypes.instanceOf(Array).isRequired,
  couponName: PropTypes.string.isRequired,
  sport: PropTypes.instanceOf(Object).isRequired,
  visibleEvents: PropTypes.instanceOf(Object).isRequired,
  treeType: PropTypes.string.isRequired,
  firstScoreboard: PropTypes.instanceOf(Object).isRequired,
  widget: PropTypes.bool.isRequired,
};

const makeMapStateToProps = () => {
  const getVisibleEvents = makeGetVisibleEvents();
  const getFirstScoreboard = makeGetFirstScoreboard();

  return (state, ownProps) => ({
    visibleEvents: getVisibleEvents(state, ownProps),
    firstScoreboard: getFirstScoreboard(state, ownProps),
    treeType: state.inplay.treeType,
  });
};

export default connect(makeMapStateToProps, null)(withCompetitionContext(CompetitionComponent));
