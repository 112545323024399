import {
  pickOutcomesByKeys,
  orderOutcomesByPo,
} from 'InPlayOverview/helpers/outcomes';

const orderedOutcomes = (outcomes, keyOrder, template, showOutcomeDesc, suspended) => {
  const keys = keyOrder.filter((key) => key !== '');
  // if we have all keys assigned, or more keys than outcomes
  if (outcomes.length <= keys.length) {
    return pickOutcomesByKeys(outcomes, keys, showOutcomeDesc, suspended);
  }

  // fall back to sorting by po instead of picking elements
  return orderOutcomesByPo(outcomes, showOutcomeDesc, suspended);
};

export default orderedOutcomes;
