import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetOutcomes } from 'InPlayOverview/selectors';
import { withCompetitionContext } from 'InPlayOverview/helpers/competition';
import orderedOutcomes from 'InPlayOverview/helpers/ordered_outcomes';
import { isMarketSuspended } from 'sportsbook-helpers';
import SeeMoreMarkets from './see_more_markets';

export const OutcomesComponent = ({ marketType, outcomes: outcomesProp, market }) => {
  const template = marketType.couponName;
  const showOutcomeDesc = ['template_a', 'template_b', 'template_b1', 'template_e'].includes(template);

  const outcomes = outcomesProp[market.id] || [];

  if (outcomes.length === 0) {
    return <SeeMoreMarkets />;
  }

  return orderedOutcomes(
    outcomes,
    (marketType.outcome_keys || []),
    template,
    showOutcomeDesc,
    isMarketSuspended(market),
  );
};

OutcomesComponent.propTypes = {
  marketType: PropTypes.instanceOf(Object).isRequired,
  market: PropTypes.instanceOf(Object).isRequired,
  outcomes: PropTypes.instanceOf(Object).isRequired,
};

const makeMapStateToProps = () => {
  const getOutcomes = makeGetOutcomes();

  const mapStateToProps = (state, ownProps) => ({
    outcomes: getOutcomes(state, { markets: [ownProps.market] }),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(withCompetitionContext(OutcomesComponent));
